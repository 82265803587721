import React from "react";
import {
  CssBaseline,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Container,
  withStyles,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { firebase } from "./../Firebase";
import Snackbar from "./../Components/Snackbar";
import { green } from "@material-ui/core/colors";
import jwt_decode from "jwt-decode";
import LoginIcon from "@mui/icons-material/Login";

const styles = (theme) => ({
  appbar: theme.mixins.toolbar,
  logo: {
    textAlign: "center",
    width: "50%",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(22),
    marginBottom: theme.spacing(7),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(15),
      minHeight: (window.innerHeight * 43.7) / 100,
    },
    minHeight: (window.innerHeight * 53) / 100,
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  mainHeader: {
    fontWeight: "bold",
    fontSize: theme.spacing(6),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      snackbarMessage: "",
      snackbarSeverity: "",
      snackbarOpen: false,
      disabled: false,
    };
  }

  updateEmailStatus = async (email) => {
    const db = firebase.firestore();

    // Update user's emailStatus in users collection
    const userQuery = await db
      .collection("users")
      .where("email", "==", email)
      .get();
    if (!userQuery.empty) {
      const userDoc = userQuery.docs[0];
      await userDoc.ref.update({
        emailStatus: "Verified",
      });

      // Get the user's candidateID if it exists
      const userData = userDoc.data();
      if (userData.candidateID) {
        // Update emailStatus in proposers subcollection
        const proposersQuery = await db
          .collection("users")
          .doc(userData.candidateID)
          .collection("proposers")
          .where("email", "==", email)
          .get();

        if (!proposersQuery.empty) {
          await proposersQuery.docs[0].ref.update({
            emailStatus: "Verified",
          });
        }

        // Update emailStatus in seconder subcollection
        const seconderQuery = await db
          .collection("users")
          .doc(userData.candidateID)
          .collection("seconder")
          .where("email", "==", email)
          .get();

        if (!seconderQuery.empty) {
          await seconderQuery.docs[0].ref.update({
            emailStatus: "Verified",
          });
        }

        // Update emailStatus in campaigner subcollection
        const campaignerQuery = await db
          .collection("users")
          .doc(userData.candidateID)
          .collection("campaigner")
          .where("email", "==", email)
          .get();

        if (!campaignerQuery.empty) {
          await campaignerQuery.docs[0].ref.update({
            emailStatus: "Verified",
          });
        }
      }
    }
  };

  login = async (e) => {
    e.preventDefault();

    const loginComponent = this;

    let date1 = new Date();
    let date2 = new Date("January 10, 2025 18:00:00");
    let date3 = new Date("January 15, 2025 00:00:00");

    if (
      date1 < date2 &&
      this.state.email !== "akankshab22@iitk.ac.in" &&
      this.state.email !== "souravd23@iitk.ac.in"
    ) {
      loginComponent.renderSnackbar(
        "Nominations filling starts at January 8, 2024!",
        "error"
      );
      return;
    }

    if (
      date1 > date3 &&
      this.state.email !== "akankshab22@iitk.ac.in" &&
      this.state.email !== "souravd23@iitk.ac.in"
    ) {
      loginComponent.renderSnackbar("Nominations filling has ended!", "error");
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.email !== "" && this.state.password !== "") {
      if (
        this.state.email.endsWith("@iitk.ac.in") &&
        re.test(String(this.state.email).toLowerCase())
      ) {
        loginComponent.setState({
          disabled: !loginComponent.state.disabled,
        });

        try {
          const userCredential = await firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password);

          if (!userCredential.user.emailVerified) {
            await userCredential.user.sendEmailVerification();
            await firebase.auth().signOut();
            loginComponent.renderSnackbar(
              "Email not verified, check your inbox to verify your email!",
              "info"
            );
            loginComponent.setState({
              disabled: false,
            });
            return;
          }

          // Update email status after successful verification
          await this.updateEmailStatus(this.state.email);

          const token = await userCredential.user.getIdToken();
          localStorage.setItem("token", token);
          loginComponent.renderSnackbar("LoggedIn Successfully!", "success");
          window.open("/", "_self");
        } catch (error) {
          console.log(error);
          loginComponent.renderSnackbar(error.message, "error");
          loginComponent.setState({
            disabled: false,
          });
        }
      } else {
        loginComponent.renderSnackbar("Use your IITK Email Address!", "error");
      }
    } else {
      loginComponent.renderSnackbar("Please fill all the fields!", "error");
    }
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  renderSnackbar = (snackbarMessage, snackbarSeverity) => {
    this.setState({
      snackbarMessage: snackbarMessage,
      snackbarSeverity: snackbarSeverity,
      snackbarOpen: true,
    });
  };

  componentDidMount() {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
    } else {
      var decoded = jwt_decode(localStorage.getItem("token"));
      var dateNow = new Date();

      if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
        localStorage.removeItem("token");
        window.open("/login", "_self");
      } else {
        window.open("/", "_self");
      }
    }
  }

  login = async (e) => {
    e.preventDefault();

    const loginComponent = this;

    let date1 = new Date();
    let date2 = new Date("January 10, 2025 18:00:00");
    let date3 = new Date("January 15, 2025 00:00:00");

    if (
      date1 < date2 &&
      this.state.email !== "akankshab22@iitk.ac.in" &&
      this.state.email !== "souravd23@iitk.ac.in"
    ) {
      loginComponent.renderSnackbar(
        "Nominations filling starts at January 8, 2024!",
        "error"
      );
      return;
    }

    if (
      date1 > date3 &&
      this.state.email !== "akankshab22@iitk.ac.in" &&
      this.state.email !== "souravd23@iitk.ac.in"
    ) {
      loginComponent.renderSnackbar("Nominations filling has ended!", "error");
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.email !== "" && this.state.password !== "") {
      if (
        this.state.email.endsWith("@iitk.ac.in") &&
        re.test(String(this.state.email).toLowerCase())
      ) {
        loginComponent.setState({
          disabled: !loginComponent.state.disabled,
        });

        try {
          const userCredential = await firebase
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password);

          if (!userCredential.user.emailVerified) {
            await userCredential.user.sendEmailVerification();
            await firebase.auth().signOut();
            loginComponent.renderSnackbar(
              "Email not verified, check your inbox to verify your email!",
              "info"
            );
            loginComponent.setState({
              disabled: false,
            });
            return;
          }

          // Update email status after successful verification
          await this.updateEmailStatus(this.state.email);

          const token = await userCredential.user.getIdToken();
          localStorage.setItem("token", token);
          loginComponent.renderSnackbar("LoggedIn Successfully!", "success");
          window.open("/", "_self");
        } catch (error) {
          console.log(error);
          loginComponent.renderSnackbar(error.message, "error");
          loginComponent.setState({
            disabled: false,
          });
        }
      } else {
        loginComponent.renderSnackbar("Use your IITK Email Address!", "error");
      }
    } else {
      loginComponent.renderSnackbar("Please fill all the fields!", "error");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <CssBaseline />

        {this.state.snackbarOpen ? (
          <Snackbar
            open={this.state.snackbarOpen}
            close={() => this.setState({ snackbarOpen: false })}
            message={this.state.snackbarMessage}
            severity={this.state.snackbarSeverity}
          />
        ) : null}

        <div className={classes.appbar}></div>

        <Container maxWidth="xl" className={classes.container}>
          <Typography
            variant="h4"
            align="center"
            className={classes.mainHeader}
            gutterBottom
          >
            Login
          </Typography>
          <Container maxWidth="sm">
            <TextField
              className={classes.inputField}
              name="email"
              value={this.state.email.trim()}
              label="Email-ID"
              onChange={this.onChange}
              placeholder="Enter your IITK email address"
              variant="outlined"
            />
            <TextField
              className={classes.inputField}
              name="password"
              type="password"
              value={this.state.password}
              label="Password"
              onChange={this.onChange}
              variant="outlined"
            />
            {!this.state.isLogin ? (
              <BottomNavigation
                showLabels
                onChange={(event, value) => {
                  if (!this.state.isLogin) {
                    window.open("/forgot-password", "_self");
                  }
                }}
                className={classes.bottomNavigation}
              >
                <BottomNavigationAction
                  className={classes.actionButton}
                  label="ForgotPassword"
                  icon={<LoginIcon />}
                />
              </BottomNavigation>
            ) : null}
            <div className={classes.wrapper}>
              <Button
                onClick={this.login}
                variant="contained"
                color="primary"
                fullWidth
                disabled={this.state.disabled}
              >
                Login
              </Button>
              {this.state.disabled && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Container>
        </Container>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Login);
