import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Hidden, IconButton, Link, AppBar, Toolbar, Typography, Button, withStyles, Drawer } from '@material-ui/core';
import { SignOut } from './Signout';
import DehazeIcon from '@material-ui/icons/Dehaze';
import jwt_decode from 'jwt-decode';
import { firebase } from './../Firebase';
import logo from './../assets/images/logo.png';
const styles = (theme) => ({
	show: {
		transform: 'translateY(0)',
		transition: 'transform .5s',
	},
	hide: {
		transform: 'translateY(-110%)',
		transition: 'transform .5s',
	},
	logo: {
		width: theme.spacing(6),
		marginTop: theme.spacing(1.5),
		// marginBottom: theme.spacing(0),
	},
	heading: {
		flexGrow: 1,
		fontWeight: 'bold',
		fontSize: theme.spacing(4),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),

		[theme.breakpoints.down('xs')]: { fontSize: theme.spacing(3) },
	},
	link: {
		color: 'white',
		'&:hover': {
			color: '#01e76c',
			textDecoration: 'underline',
		},
	},
	appbar: {
		backgroundColor: '#000',
		marginBottom: theme.spacing(4),
	},
	navButtons: {
		'&:hover': {
			color: '#01e76c',
		},
		fontWeight: '800',
		color: 'white',
	},
	navOpener: {
		color: 'white',
		marginRight: theme.spacing(2),
	},
	drawerPills: {
		color: 'black',
		fontWeight: '800',
		'&:hover': {
			color: '#01e76c',
		},
	},
	drawer: {
		marginBottom: theme.spacing(2),
	},
	avatar: {
		backgroundColor: '#01e76c',
		fontWeight: 'bold',
		color: 'black',
		'&:hover': {
			cursor: 'pointer',
		},
		width: theme.spacing(6),
		height: theme.spacing(6),
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
});

class CollapsibleAppBar extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			shouldShow: null,
			isLogin: false,
			isDrawerOpened: false,
			isAdmin: false,
			image: '',
			name: '',
		};

		this.lastScroll = null;
	}

	componentDidMount() {
		const navbarComponent = this;
		window.addEventListener('scroll', this.handleScroll, { passive: true });
	
		this.setState({
			isLogin: localStorage.getItem('token') === null || localStorage.getItem('token') === '' ? false : true,
		});
	
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
			return;
		}
	
		const decoded = jwt_decode(localStorage.getItem('token'));
		firebase
			.firestore()
			.collection('users')
			.where('email', '==', decoded.email)
			.onSnapshot((querySnapshot) => {
				const profileData = [];
	
				querySnapshot.forEach(function (doc) {
					profileData.push(doc.data());
				});
	
				const userRole = profileData[0].role;
				const isCandidate = userRole === 'Candidate';
				const isProposer = userRole === 'Proposers' && profileData[0].status === "Accepted";
	
				navbarComponent.setState({
					isAdmin: profileData[0].isAdmin,
					image: profileData[0].imageURL === undefined ? '' : profileData[0].imageURL,
					name: profileData[0].name,
					isCandidate,
					isProposer,
				});
			});
	}
	

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	toggleDrawerStatus = () => {
		this.setState({
			isDrawerOpened: true,
		});
	};

	closeDrawer = () => {
		this.setState({
			isDrawerOpened: false,
		});
	};

	handleScroll = () => {
		const lastScroll = window.scrollY;

		if (lastScroll === this.lastScroll) {
			return;
		}

		const shouldShow = this.lastScroll !== null ? lastScroll < this.lastScroll : null;

		if (shouldShow !== this.state.shouldShow) {
			this.setState((prevState, props) => ({
				...prevState,
				shouldShow,
			}));
		}

		this.lastScroll = lastScroll;
	};

	redirectToProfile = () => {
		window.open('/profile', '_self');
	};

	render() {
		const { classes } = this.props;

		const navOptions = [
			['Login', '/login', !this.state.isLogin],
			['Signup', '/signup', !this.state.isLogin],
			// ['Nomination', '/nomination', this.state.isLogin],
			['MyTeam', '/myteam', this.state.isLogin && this.state.isCandidate],
			// ['Team', '/team', this.state.isLogin && this.state.isProposer],
			['Admin', '/admin', this.state.isAdmin],
		];

		return (
			<>
				<AppBar position='fixed' color='default' className={`${classes.appbar} ${this.state.shouldShow === null ? '' : this.state.shouldShow ? classes.show : classes.hide}`}>
					<Toolbar>
						{this.state.isLogin ? (
							<>
								<Hidden mdUp>
									<IconButton onClick={this.toggleDrawerStatus} className={classes.navOpener} style={{ color: 'white' }}>
										{!this.state.isDrawerOpened ? <DehazeIcon /> : null}
									</IconButton>
								</Hidden>{' '}
								<Typography variant='h6' className={classes.heading}>
									<Link href='/' underline='none' className={classes.link} variant='inherit'>
										<img src={logo} alt='logo' className={classes.logo} />
									</Link>
								</Typography>
							</>
						) : (
							<>
								<Typography variant='h6' className={classes.heading}>
									<Link href='/' underline='none' className={classes.link} variant='inherit'>
										<img src={logo} alt='logo' className={classes.logo} />
									</Link>
								</Typography>
								<Hidden mdUp>
									<IconButton onClick={this.toggleDrawerStatus} className={classes.navOpener} style={{ color: 'white' }}>
										{!this.state.isDrawerOpened ? <DehazeIcon /> : null}
									</IconButton>
								</Hidden>
							</>
						)}

						<Hidden smDown>
							{navOptions.map((option, index) => (
								<>
									{option[2] ? (
										<Button key={index} href={option[1]} color='inherit' size='large' className={classes.navButtons}>
											{option[0]}
										</Button>
									) : null}
								</>
							))}

							{this.state.isLogin ? (
								<Button color='inherit' size='large' className={classes.navButtons} onClick={() => SignOut()}>
									Logout
								</Button>
							) : null}
						</Hidden>

						{this.state.isLogin ? (
							<>
								{this.state.image === '' ? (
									<Avatar alt={this.state.name} className={classes.avatar} onClick={() => this.redirectToProfile()}>
										{this.state.name[0]}
									</Avatar>
								) : (
									<Avatar alt={this.state.name} className={classes.avatar} onClick={() => this.redirectToProfile()} src={this.state.image}></Avatar>
								)}
							</>
						) : null}
					</Toolbar>
				</AppBar>

				<Drawer anchor='left' variant='temporary' open={this.state.isDrawerOpened} onClose={this.closeDrawer}>
					{navOptions.map((option, index) => (
						<>
							{option[2] ? (
								<Button key={index} href={option[1]} color='inherit' size='large' className={classes.drawerPills}>
									{option[0]}
								</Button>
							) : null}
						</>
					))}

					{this.state.isLogin ? (
						<Button color='inherit' size='large' className={classes.drawerPills} onClick={() => SignOut()}>
							Logout
						</Button>
					) : null}
				</Drawer>
			</>
		);
	}
}

CollapsibleAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CollapsibleAppBar);
