import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  Typography,
  Container,
  withStyles,
} from "@material-ui/core";
import { firebase } from "./../Firebase";
import Snackbar from "./../Components/Snackbar";
import Loader from "./../Components/Loader";
import jwt_decode from "jwt-decode";
import NotFound from "./404";

const styles = (theme) => ({
  appbar: theme.mixins.toolbar,
  logo: {
    textAlign: "center",
    width: "50%",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    fontWeight: "bold",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    fontSize: theme.spacing(6),
  },
  subHeader: {
    fontWeight: "800",
    fontSize: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { marginTop: theme.spacing(15) },
    marginBottom: theme.spacing(6),
    minHeight: (window.innerHeight * 43.7) / 100,
  },
});

class Nomination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userId: "",
      snackbarMessage: "",
      snackbarSeverity: "",
      snackbarOpen: false,
      isLoading: true,
      isLogin: false,
      campaigner: [],
      proposers: [],
      seconder: [],
    };
  }
  fetchAssociatedRoles = (candidateID) => {
    const profileComponent = this;
  
    // Fetch proposers with emailStatus: "Verified" and status: "Accepted"
    firebase
      .firestore()
      .collection("users")
      .doc(candidateID)
      .collection("proposers")
      .where("emailStatus", "==", "Verified")
      .where("status", "==", "Accepted")
      .get()
      .then((snapshot) => {
        const proposers = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        profileComponent.setState({ proposers });
      });
  
    // Fetch seconders with emailStatus: "Verified" and status: "Accepted"
    firebase
      .firestore()
      .collection("users")
      .doc(candidateID)
      .collection("seconder")
      .where("emailStatus", "==", "Verified")
      .where("status", "==", "Accepted")
      .get()
      .then((snapshot) => {
        const seconder = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        profileComponent.setState({ seconder });
      });
  
    // Fetch campaigners with emailStatus: "Verified" and status: "Accepted"
    firebase
      .firestore()
      .collection("users")
      .doc(candidateID)
      .collection("campaigner")
      .where("emailStatus", "==", "Verified")
      .where("status", "==", "Accepted")
      .get()
      .then((snapshot) => {
        const campaigner = snapshot.docs.map((doc) => ({
          ...doc.data(),
        }));
        profileComponent.setState({ campaigner, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: "Failed to fetch associated roles.",
          snackbarSeverity: "error",
        });
      });
  };
  

  componentDidMount() {
    const profileComponent = this;

    if (localStorage.getItem("token")) {
      const decoded = jwt_decode(localStorage.getItem("token"));

      firebase
        .firestore()
        .collection("users")
        .where("email", "==", decoded.email)
        .onSnapshot(
          (querySnapshot) => {
            const profileData = [];
            querySnapshot.forEach((doc) => profileData.push(doc.data()));

            if (profileData.length > 0) {
              profileComponent.setState({ isAdmin: profileData[0].isAdmin });
            }
          },
          (error) => {
            console.error("Error fetching profile data:", error);
            this.setState({ isLoading: false });
          }
        );
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ userEmail: user.email, isLogin: true }, () => {
          const fetchUsers = firebase
            .firestore()
            .collection("users")
            .where("email", "==", this.state.userEmail)
            .get();

          fetchUsers.then(
            (userSnapshot) => {
              const userDoc = userSnapshot.docs[0];
              const userId = userDoc?.id;

              if (userId) {
                this.setState({ userId });
                console.log("User ID: ", userId);
                this.fetchUserRoles(userId);
              }
            },
            (error) => {
              console.error("Error fetching user by email:", error);
              this.setState({ isLoading: false });
            }
          );
        });
      } else {
        this.setState({ isLoading: false, isLogin: false });
      }
    });
    const decoded = jwt_decode(localStorage.getItem("token"));
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", decoded.email)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const candidateID = userDoc.data()?.candidateID;

          if (candidateID) {
            this.fetchAssociatedRoles(candidateID);
          } else {
            this.setState({
              isLoading: false,
              snackbarOpen: true,
              snackbarMessage: "No candidate ID found for the user.",
              snackbarSeverity: "warning",
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching user document:", error);
        this.setState({
          isLoading: false,
          snackbarOpen: true,
          snackbarMessage: "Failed to fetch user details.",
          snackbarSeverity: "error",
        });
      });
  }

  render() {
    const { classes } = this.props;
  
    return (
      <>
        <CssBaseline />
        <div className={classes.appbar}></div>

        {this.state.snackbarOpen && (
          <Snackbar
            open={this.state.snackbarOpen}
            close={() => this.setState({ snackbarOpen: false })}
            message={this.state.snackbarMessage}
            severity={this.state.snackbarSeverity}
          />
        )}
  
        {this.state.isLoading ? (
          <Loader />
        ) : this.state.isLogin ? (
          <Container maxWidth="md" className={classes.container}>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              className={classes.heading}
            >
              My Team
            </Typography>
  
            <Typography variant="h5" align="center" className={classes.subHeader}>
              Proposers
            </Typography>
            <List component="nav">
              {this.state.proposers.length > 0 ? (
                this.state.proposers.map((proposer, idx) => (
                  <ListItem button key={idx}>
                    <ListItemText align="center"
                      primary={`${proposer.name} || Roll: ${proposer.roll}`}
                      secondary={`${proposer.email} || ${proposer.mobileNumber}`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="subtitle1" align="center">
                  No proposers available
                </Typography>
              )}
            </List>
  
            <Typography variant="h5" align="center" className={classes.subHeader}>
              Seconders
            </Typography>
            <List component="nav">
              {this.state.seconder.length > 0 ? (
                this.state.seconder.map((seconder, idx) => (
                  <ListItem button key={idx}>
                    <ListItemText align="center"
                      primary={`${seconder.name} || Roll: ${seconder.roll}`}
                      secondary={`${seconder.email} | ${seconder.mobileNumber}`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="subtitle1" align="center">
                  No seconders available
                </Typography>
              )}
            </List>
  
            <Typography variant="h5" align="center" className={classes.subHeader}>
              Campaigners
            </Typography>
            <List component="nav">
              {this.state.campaigner.length > 0 ? (
                this.state.campaigner.map((campaigner, idx) => (
                  <ListItem button key={idx}>
                    <ListItemText align="center"
                      primary={`${campaigner.name} || Roll: ${campaigner.roll}`}
                      secondary={`${campaigner.email} | ${campaigner.mobileNumber}`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="subtitle1" align="center">
                  No campaigners available
                </Typography>
              )}
            </List>
          </Container>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
  
}

export default withStyles(styles, { withTheme: true })(Nomination);
