import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import theme from './Views/Theme';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Profile from './Pages/Profile';
import MyTeam from './Pages/MyTeam';
import Team from './Pages/Team';
import Nomination from './Pages/Nomination';
import Admin from './Pages/Admin';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import NotFound from './Pages/404';
import ForgotPassword from './Pages/ForgotPassword';
import { firebase } from "./Firebase";

const themes = responsiveFontSizes(theme);

function App() {
	var hist = createBrowserHistory();
	return (
		<ThemeProvider theme={themes}>
			<Router history={hist} style={{ backgroundColor: '#f7f6f6', flexGrow: 1 }}>
				<Navbar />
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/login' component={Login} />
					<Route exact path='/signup' component={Signup} />
					<Route exact path='/profile' component={Profile} />
					<Route exact path='/myteam' component={MyTeam} />
					<Route exact path='/team' component={Team} />
					<Route exact path='/nomination' component={Nomination} />
					<Route exact path='/forgot-password' component={ForgotPassword} />
					<Route exact path='/admin' component={Admin} />
					<Route path='' component={NotFound} />
				</Switch>
				<Footer />
			</Router>
		</ThemeProvider>
	);
}

firebase.auth().onAuthStateChanged(async (user) => {
	if (user && user.emailVerified) {
	  try {
		const userEmail = user.email.toLowerCase();
		const userCollectionRef = firebase.firestore().collection("users");
  
		// Fetch the main user document
		const userSnapshot = await userCollectionRef
		  .where("email", "==", userEmail)
		  .get();
  
		if (userSnapshot.empty) {
		  console.error("User document not found!");
		  return;
		}
  
		const userDoc = userSnapshot.docs[0];
		const userDocRef = userCollectionRef.doc(userDoc.id);
  
		// Update emailStatus in the main document
		await userDocRef.update({ emailStatus: "Verified" });
		console.log("Main user email status updated to Verified");
  
		// Proceed to nested sub-collection update if role applies
		const userData = userDoc.data();
		const { role, post } = userData;
  
		if (
		  ["Proposers", "Seconder", "Campaigner"].includes(role) &&
		  post
		) {
		  const candidateSnapshot = await userCollectionRef
			.where("role", "==", "Candidate")
			.where("post", "==", post)
			.get();
  
		  if (candidateSnapshot.empty) {
			console.error(`Candidate document for post ${post} not found.`);
			return;
		  }
  
		  const candidateDoc = candidateSnapshot.docs[0];
		  const candidateDocRef = userCollectionRef.doc(candidateDoc.id);
  
		  // Update the relevant sub-collection
		  const subCollectionName = role.toLowerCase(); // 'proposers', 'seconder', or 'campaigner'
		  const subCollectionRef = candidateDocRef.collection(subCollectionName);
  
		  const subSnapshot = await subCollectionRef
			.where("email", "==", userEmail)
			.get();
  
		  if (subSnapshot.empty) {
			console.error(
			  `No ${role} document found for email ${userEmail} under post ${post}.`
			);
			return;
		  }
  
		  const subDoc = subSnapshot.docs[0];
		  const subDocRef = subCollectionRef.doc(subDoc.id);
  
		  await subDocRef.update({ emailStatus: "Verified" });
		  console.log(
			`${role} email status updated to Verified under candidate post: ${post}`
		  );
		}
	  } catch (error) {
		console.error("An error occurred: ", error);
	  }
	}
  });
  
  

export default App;
