import React from "react";
import {
  FormControl,
  CircularProgress,
  InputLabel,
  Select,
  CssBaseline,
  Typography,
  Button,
  TextField,
  Container,
  withStyles,
} from "@material-ui/core";
import { firebase } from "./../Firebase";
import { green } from "@material-ui/core/colors";
import Snackbar from "./../Components/Snackbar";
import jwt_decode from "jwt-decode";

const styles = (theme) => ({
  appbar: theme.mixins.toolbar,
  logo: {
    textAlign: "center",
    width: "50%",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: "relative",
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { marginTop: theme.spacing(10) },
    minHeight: (window.innerHeight * 43.7) / 100,
  },
  mainHeader: {
    fontWeight: "bold",
    fontSize: theme.spacing(6),
  },
});

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "Candidate",
      post: "",
      roll: "",
      mobileNumber: "",
      disabled: false,
      snackbarMessage: "",
      snackbarSeverity: "",
      snackbarOpen: false,
      candidates: [],
      selectedCandidate: "",
    };
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  renderSnackbar = (snackbarMessage, snackbarSeverity) => {
    this.setState({
      snackbarMessage: snackbarMessage,
      snackbarSeverity: snackbarSeverity,
      snackbarOpen: true,
    });
  };

  signup = (e) => {
    e.preventDefault();
    const { selectedCandidate } = this.state;

    const signupComponent = this;

    const { name, email, password, confirmPassword, role, post, mobileNumber } =
      signupComponent.state;
    email.toLowerCase();

    if (["Proposers", "Seconder"].includes(role) && !selectedCandidate) {
      this.renderSnackbar("Please select a candidate", "error");
      return;
    }

    let date1 = new Date();
    let date2 = new Date("January 10, 2025 18:00:00");
    let date3 = new Date("January 12, 2025 04:00:00");

    if (
      date1 < date2 &&
      email !== "akankshab22@iitk.ac.in" &&
      email !== "souravd23@iitk.ac.in"
    ) {
      signupComponent.renderSnackbar(
        "Nominations filling starts at January 8, 12 AM!",
        "error"
      );
      return;
    }

    if (
      date1 > date3 &&
      email !== "akankshab22@iitk.ac.in" &&
      email !== "souravd23@iitk.ac.in"
    ) {
      signupComponent.renderSnackbar("Nominations filling has ended!", "error");
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      role !== ""
    ) {
      if (password === confirmPassword) {
        if (
          // (role === "Candidate" && post !== "") ||
          // role === "Proposers" ||
          // role === "Seconder" ||
          role === "Campaigner"
        ) {
          if (
            this.state.email.endsWith("@iitk.ac.in") &&
            re.test(String(this.state.email).toLowerCase())
          ) {
            if (mobileNumber.length === 10) {
              signupComponent.setState({
                disabled: !signupComponent.state.disabled,
              });

              firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                  const user = userCredential.user;

                  user
                    .updateProfile({
                      displayName: name,
                    })
                    .then(() => {
                      console.log("Username updated!");

                      user
                        .sendEmailVerification()
                        .then(() => {
                          console.log("Email Sent!");
                          signupComponent.addDataToFirestore();
                          signupComponent.renderSnackbar(
                            "Signup successful! Please verify your email & log in.",
                            "success"
                          );
                        })
                        .catch((error) => {
                          alert(error);
                          signupComponent.setState({
                            disabled: !signupComponent.state.disabled,
                          });
                        });
                    })
                    .catch((error) => {
                      console.log(error, "name");
                    });

                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      console.log("Signed Out");
                    })
                    .catch((error) => {
                      console.log(error, "auth");
                      signupComponent.setState({
                        disabled: !signupComponent.state.disabled,
                      });
                    });
                })
                .catch((error) => {
                  console.log(error, "overall");
                  signupComponent.renderSnackbar(error.message, "error");
                  signupComponent.setState({
                    disabled: !signupComponent.state.disabled,
                  });
                });
            } else {
              signupComponent.renderSnackbar(
                "Please enter a valid mobile number!",
                "error"
              );
            }
          } else {
            signupComponent.renderSnackbar("Enter IITK Email Address!", "info");
          }
        } else {
          signupComponent.renderSnackbar(
            "Please fill all the fields!",
            "error"
          );
        }
      } else {
        signupComponent.renderSnackbar("Passwords do not match!", "error");
      }
    } else {
      signupComponent.renderSnackbar("Please fill all the fields!", "error");
    }
  };

  fetchCandidatesForPost = (post) => {
    firebase
      .firestore()
      .collection("users")
      .where("role", "==", "Candidate")
      .where("post", "==", post)
      .get()
      .then((querySnapshot) => {
        const candidates = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        this.setState({ candidates });
      })
      .catch((error) => {
        console.error("Error fetching candidates: ", error);
      });
  };

  addDataToFirestore = () => {
    const signupComponent = this;
    let { name, email, role, post, mobileNumber, roll, selectedCandidate } =
      signupComponent.state;
    email = email.toLowerCase();

    let selectedCandidateName = "";
    let selectedCandidateID = "";
    if (selectedCandidate) {
      const selectedCandidateData = this.state.candidates.find(
        (candidate) => candidate.id === selectedCandidate
      );
      selectedCandidateName = selectedCandidateData
        ? selectedCandidateData.name
        : "";
      selectedCandidateID = selectedCandidateData
        ? selectedCandidateData.id
        : "";
    }

    if (role === "Candidate") {
      // Add candidate details to Firestore
      firebase
        .firestore()
        .collection("users")
        .add({
          name: name,
          email: email,
          role: role,
          post: post,
          mobileNumber: mobileNumber,
          roll: roll,
          emailStatus: "Pending",
          isAdmin: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          signupComponent.renderSnackbar(
            "Candidate created successfully!",
            "success"
          );
          signupComponent.resetForm();
        })
        .catch((error) => {
          console.error("Error adding candidate document: ", error);
          signupComponent.toggleDisabled();
        });
    } else {
      // First add to users collection
      firebase
        .firestore()
        .collection("users")
        .add({
          name: name,
          email: email,
          role: role,
          post: post,
          mobileNumber: mobileNumber,
          roll: roll,
          candidateID: selectedCandidateID,
          candidateName: selectedCandidateName,
          emailStatus: "Pending",
          isAdmin: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          // After successfully adding to users, add to candidate's subcollection
          const candidateRef = firebase
            .firestore()
            .collection("users")
            .doc(selectedCandidateID);

          const subCollectionName = role.toLowerCase();

          // Add to candidate's subcollection
          candidateRef
            .collection(subCollectionName)
            .add({
              name: name,
              email: email,
              role: role,
              mobileNumber: mobileNumber,
              roll: roll,
              candidateID: selectedCandidateID,
              emailStatus: "Pending",
              status: "Pending",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              signupComponent.renderSnackbar(
                `${role} added successfully under candidate ${selectedCandidateName}!`,
                "success"
              );
              signupComponent.resetForm();
            })
            .catch((error) => {
              console.error("Error adding to sub-collection: ", error);
              signupComponent.toggleDisabled();
            });
        })
        .catch((error) => {
          console.error("Error adding to users collection: ", error);
          signupComponent.toggleDisabled();
        });
    }
  };

  componentDidMount() {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
    } else {
      var decoded = jwt_decode(localStorage.getItem("token"));
      var dateNow = new Date();

      if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
        localStorage.removeItem("token");
        window.open("/login", "_self");
      } else {
        window.open("/", "_self");
      }
    }

    firebase
      .firestore()
      .collection("users")
      .where("role", "==", "Candidate")
      .where("emailStatus", "==", "Verified")
      .get()
      .then((querySnapshot) => {
        const candidates = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          post: doc.data().post,
        }));

        this.setState({ candidates });
      })
      .catch((error) => {
        console.error("Error fetching candidates: ", error);
      });
  }
  

  isFormValid() {
    const {
      name,
      email,
      password,
      confirmPassword,
      role,
      mobileNumber,
      roll,
      post,
      selectedCandidate,
    } = this.state;
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validMobile = mobileNumber.length === 10;

    if (
      !name ||
      !email ||
      !password ||
      !confirmPassword ||
      !role ||
      !roll ||
      !post ||
      (role !== "Candidate" && !selectedCandidate)
    )
      return false;
    if (password !== confirmPassword) return false;
    if (!validEmail || !validMobile) return false;

    return true;
  }

  render() {
    const { classes } = this.props;

    const candidateDetails = [
      {
        label: "Full Name",
        name: "name",
        type: "text",
        required: true,
        value: this.state.name,
        placeholder: "Enter your full name",
      },
      {
        label: "Email-ID",
        name: "email",
        type: "email",
        required: true,
        value: this.state.email,
        placeholder: "Enter your IITK email address",
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        required: true,
        value: this.state.password,
        placeholder: "Enter your password",
      },
      {
        label: "Confirm Password",
        name: "confirmPassword",
        type: "password",
        required: true,
        value: this.state.confirmPassword,
        placeholder: "Enter your password once again",
      },
      {
        label: "Mobile Number",
        name: "mobileNumber",
        type: "tel",
        required: true,
        value: this.state.mobileNumber,
        placeholder: "Enter your mobile number",
      },
      {
        label: "Roll Number",
        name: "roll",
        type: "string",
        required: true,
        value: this.state.roll,
        placeholder: "Enter your IITK Roll Number",
      },
    ];

    return (
      <>
        <CssBaseline />
        <div className={classes.appbar}></div>

        {this.state.snackbarOpen ? (
          <Snackbar
            open={this.state.snackbarOpen}
            close={() => this.setState({ snackbarOpen: false })}
            message={this.state.snackbarMessage}
            severity={this.state.snackbarSeverity}
          />
        ) : null}

        <Container maxWidth="sm" className={classes.container}>
          <Typography
            variant="h4"
            align="center"
            className={classes.mainHeader}
            gutterBottom
          >
            SignUp
          </Typography>

          {candidateDetails.map((item, index) => (
            <TextField
              name={item.name}
              value={item.value}
              label={item.label}
              type={item.type}
              required={item.required}
              placeholder={item.placeholder}
              onChange={this.onChange}
              variant="outlined"
              className={classes.inputField}
            />
          ))}

          <FormControl
            variant="outlined"
            required={true}
            className={classes.inputField}
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Select your Role
            </InputLabel>
            <Select
              native
              value={this.state.role}
              onChange={this.onChange}
              label="Select your Role"
              disabled={false}
              inputProps={{
                name: "role",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              {/* <option value="Candidate">Candidate</option>
              <option value="Proposers">Proposer</option>
              <option value="Seconder">Seconder</option> */}
              <option value="Campaigner">Campaigner</option>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            required={true}
            className={classes.inputField}
          >
            <InputLabel htmlFor="post-select">For the Post of</InputLabel>
            <Select
              native
              value={this.state.post}
              onChange={(e) => {
                this.onChange(e);
                if (
                  ["Proposers", "Seconder", "Campaigner"].includes(
                    this.state.role
                  )
                ) {
                  this.fetchCandidatesForPost(e.target.value);
                }
              }}
              inputProps={{
                name: "post",
                id: "post-select",
              }}
            >
              <option aria-label="None" value="" />

              <>
                <option value="Candidate for President, Students’ Gymkhana">
                  Candidate for President, Students’ Gymkhana
                </option>
                <option value="Candidate for General Secretary (PG), Academics and Career Council">
                  Candidate for General Secretary (PG), Academics and Career
                  Council
                </option>
                <option value="Candidate for General Secretary (UG), Academics and Career Council">
                  Candidate for General Secretary (UG), Academics and Career
                  Council
                </option>
                <option value="Candidate for General Secretary, Games and Sports Council">
                  Candidate for General Secretary, Games and Sports Council
                </option>
                <option value="Candidate for General Secretary, Media and Cultural Council">
                  Candidate for General Secretary, Media and Cultural Council
                </option>
                <option value="Candidate for General Secretary, Science and Technology Council">
                  Candidate for General Secretary, Science and Technology
                  Council
                </option>
                <option value="Candidate for BT/BS Y24 Senator">
                  Candidate for BT/BS Y24 Senator
                </option>
                <option value="Candidate for BT/BS Y23 Senator">
                  Candidate for BT/BS Y23 Senator
                </option>
                <option value="Candidate for BT/BS Y22 Senator">
                  Candidate for BT/BS Y22 Senator
                </option>
                <option value="Candidate for BT/BS Y21 Senator">
                  Candidate for BT/BS Y21 Senator
                </option>
                {/* <option value="Candidate for BT/BS-MT/MS/MBA/MDes Y21 Senator">
                  Candidate for BT/BS-MT/MS/MBA/MDes Y21 Senator
                </option>
                <option value="Candidate for BT/BS-MT/MS/MBA/MDes Y20 Senator">
                  Candidate for BT/BS-MT/MS/MBA/MDes Y20 Senator
                </option> */}
                <option value="Candidate for M.Tech (2 Year) Y24 Senator">
                  Candidate for M.Tech (2 Year) Y24 Senator
                </option>
                <option value="Candidate for M.Tech (2 Year) Y23 Senator">
                  Candidate for M.Tech (2 Year) Y23 Senator
                </option>
                {/* <option value="Candidate for MSc Y23 (including MSc-PhD Y22) Senator">
                  Candidate for MSc Y23 (including MSc-PhD Y22) Senator
                </option> */}
                <option value="Candidate for MSc Y24 (including MSc-PhD Y23) Senator">
                  Candidate for MSc Y24 (including MSc-PhD Y23) Senator
                </option>
                <option value="Candidate for MSR Senator">
                  Candidate for MSR Senator
                </option>
                {/* <option value="Candidate for MBA, MDes and other two-year programmes Y24 Senator">
                  Candidate for MBA, MDes and other two-year programmes Y24
                  Senator
                </option> */}
                <option value="Candidate for MBA, MDes and other two-year programmes Y23 Senator">
                  Candidate for MBA, MDes and other two-year programmes Y23
                  Senator
                </option>
                <option value="Candidate for Senator PhD Y24 (including MSc-PhD Y22) Senator">
                  Candidate for Senator PhD Y24 (including MSc-PhD Y22) Senator
                </option>
                <option value="Candidate for Senator PhD Y23 (including MSc-PhD Y21) Senator">
                  Candidate for Senator PhD Y23 (including MSc-PhD Y21) Senator
                </option>
                <option value="Candidate for PhD Y22 and earlier batches (including MSc-PhD Y20) Senator">
                  Candidate for PhD Y22 and earlier batches (including MSc-PhD
                  Y20) Senator
                </option>
              </>
            </Select>
          </FormControl>

          {["Proposers", "Seconder", "Campaigner"].includes(
            this.state.role
          ) && (
            <FormControl
              variant="outlined"
              required={true}
              className={classes.inputField}
            >
              <InputLabel htmlFor="candidate-select">
                Select Candidate
              </InputLabel>
              <Select
                native
                value={this.state.selectedCandidate}
                onChange={this.onChange}
                inputProps={{
                  name: "selectedCandidate",
                  id: "candidate-select",
                }}
              >
                <option aria-label="None" value="" />
                {this.state.candidates.map((candidate) => (
                  <option key={candidate.id} value={candidate.id}>
                    {candidate.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <div className={classes.wrapper}>
            <Button
              disabled={!this.isFormValid() || this.state.disabled}
              variant="contained"
              color="primary"
              className={classes.buttonClassname}
              fullWidth
              onClick={this.signup}
            >
              Sign Up
            </Button>
            {this.state.disabled && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Signup);
