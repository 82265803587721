import React from 'react';
import { FormControl, InputLabel, Select, CssBaseline, Typography, Button, TextField, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';
import Loader from './../Components/Loader';
import NotFound from './404';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(4),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
	},
	container_al: {
		marginTop: theme.spacing(12),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
	mainHeader: {
		fontWeight: 'bold',
		fontSize: theme.spacing(6),
	},
	bottomInfo: {
		marginBottom: theme.spacing(2),
	},
});

class Nomination extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: '',
			userId: '',
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			isLoading: true,
			name: '',
			email: '',
			phoneNumber: '',
			address: '',
			rollno: '',
			role: '',
			allStudents: [],
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		const profileComponent = this;

		const allStudents = [];

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				profileComponent.setState({ userEmail: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('users')
						.where('email', '==', profileComponent.state.userEmail)
						.onSnapshot((querySnapshot) => {
							const profileId = [];

							querySnapshot.forEach(function (doc) {
								profileId.push(doc.id);
							});

							profileComponent.setState({
								userId: profileId[0],
							});
						});

					firebase
						.firestore()
						.collection('users')
						.onSnapshot((querySnapshot) => {
							const profileId = [];

							querySnapshot.forEach(function (doc) {
								profileId.push(doc.id);
								allStudents.push(doc.data().email);
								allStudents.push(doc.data().rollno);
							});

							for (let i = 0; i < profileId.length; i++) {
								firebase
									.firestore()
									.collection('users')
									.doc(profileId[i])
									.collection('nominees')
									.onSnapshot((querySnapshot) => {
										querySnapshot.forEach(function (doc) {
											allStudents.push(doc.data().email);
											allStudents.push(doc.data().rollno);
										});

										profileComponent.setState({
											isLoading: false,
											allStudents: allStudents,
										});
									});
							}
						});
				});
			} else {
				profileComponent.setState({ isLoading: false, isLogin: false });
			}
		});
	}

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	onNominate = (e) => {
		e.preventDefault();

		const nomineeComponent = this;

		const { name, email, phoneNumber, address, rollno, role, allStudents } = this.state;
		email.toLowerCase();

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (name !== '' && email !== '' && phoneNumber !== '' && address !== '' && role !== '' && rollno !== '') {
			if (re.test(String(this.state.email).toLowerCase())) {
				if (phoneNumber.length !== 10 || parseFloat(phoneNumber) < 0 || phoneNumber.includes('.')) {
					nomineeComponent.renderSnackbar('Please enter a valid phone number!', 'error');
					return;
				}
				if ((rollno.length !== 6 && rollno.length !== 8 && rollno.length!== 9) || parseFloat(rollno) < 0 || rollno.includes('.')) {
					nomineeComponent.renderSnackbar('Please enter a valid roll number!', 'error');
					return;
				}
				if (allStudents.includes(email) || allStudents.includes(rollno)) {
					nomineeComponent.renderSnackbar('Nominee already added by a candidate!', 'error');
					return;
				} else {
					firebase
						.firestore()
						.collection('users')
						.doc(this.state.userId)
						.collection('nominees')
						.add({
							name: name,
							email: email,
							phoneNumber: phoneNumber,
							address: address,
							rollno: rollno,
							role: role,
							timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						})
						.then((docRef) => {
							console.log('Document written with ID: ', docRef.id);
							nomineeComponent.setState(
								{
									name: '',
									email: '',
									phoneNumber: '',
									address: '',
									rollno: '',
									role: '',
								},
								function () {
									nomineeComponent.renderSnackbar('Nominee added successfully!', 'success');
								}
							);
						})
						.catch((error) => {
							console.error('Error adding document: ', error);
							nomineeComponent.renderSnackbar('Error in adding Nominee!', 'error');
						});
				}
			} else {
				nomineeComponent.renderSnackbar('Enter IITK Email Address!', 'info');
			}
		} else {
			nomineeComponent.renderSnackbar('Please fill all the fields!', 'error');
		}
	};

	render() {
		const { classes } = this.props;

		const textFields = [
			['Name', 'name', this.state.name, false, 'text', ''],
			['Email', 'email', this.state.email, false, 'email', 'Enter iitk email address!'],
			['Phone Number', 'phoneNumber', this.state.phoneNumber, false, 'number', ''],
			['Address', 'address', this.state.address, false, 'text', 'Enter the iitk address!'],
			['Roll Number', 'rollno', this.state.rollno, false, 'number', ''],
		];

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin ? (
							<Container maxWidth='sm' className={classes.container}>
								<Typography variant='h4' align='center' className={classes.mainHeader} gutterBottom>
									Nominations
								</Typography>

								<Typography variant='subtitle1' align='center' gutterBottom>
									Please fill in the details of your Proposers and Seconders !
								</Typography>

								{textFields.map((handle, index) => (
									<TextField
										key={index}
										variant='outlined'
										className={classes.inputField}
										type={handle[4]}
										label={handle[0]}
										name={handle[1]}
										value={handle[2]}
										onChange={this.onChange}
										disabled={handle[3]}
										required={true}
										placeholder={handle[5]}
									/>
								))}
								<FormControl variant='outlined' className={classes.inputField}>
									<InputLabel htmlFor='outlined-age-native-simple'>Enter the Role *</InputLabel>
									<Select
										native
										value={this.state.role}
										onChange={this.onChange}
										label='Type of Media'
										inputProps={{
											name: 'role',
											id: 'outlined-age-native-simple',
										}}
									>
										<option value='' />
										<option value='Proposer'>Proposer</option>
										<option value='Seconder'>Seconder</option>
									</Select>
								</FormControl>
								<Button variant='contained' color='primary' onClick={this.onNominate} fullWidth className={classes.loginButton}>
									Nominate
								</Button>

								<Typography variant='body1' align='left' gutterBottom className={classes.bottomInfo}>
									PS: Info once filled cannot be changed.
								</Typography>
							</Container>
						) : (
							<NotFound />
						)}
					</>
				)}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Nomination);
